import { useChat } from '../context/ChatProvider';

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

const useChatActions = () => {
  const {
    user,
    npc,
    setData,
    setUser,
    setNPC,
    conversation,
    setConversation,
    conversations,
    setConversations,
    setGPT,
    setError,
  } = useChat();

  async function getGPTReply(message = null) {
    try {
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${user.jwt}` },
        body: JSON.stringify({
          message,
          npc_id: npc.id,
          conversation_id: conversation?.id,
        }),
      };

      const data = await (await fetch(`${SERVER_URL}reply`, requestOptions)).json();
      setGPT(data);
      if (conversation?.id === data.id) {
        conversation.items.push({
          message: data.response,
          byNpc: true,
        });
        setConversation({ ...conversation });
      } else {
        const response = await (await fetch(`${SERVER_URL}conversation/${data.id}`)).json();
        setConversation(response);
        conversations.push(response);
        setConversations([...conversations]);
      }
      setError(null);
    } catch (err) {
      if (err && err.message) {
        setError(err.message);
      } else {
        setError(JSON.stringify(err));
      }
      if (message && conversation?.items?.length > 0) {
        conversation.items.pop();
        setConversation({ ...conversation });
      }
      throw err;
    }
  }

  async function sendMessage(message) {
    if (!message || !user || !npc) {
      return;
    }

    if (conversation) {
      conversation.items.push({
        message,
        byNpc: false,
      });
      setConversation({ ...conversation });
    } else {
      setConversation({
        items: [{
          message,
          byNpc: false,
        }],
      });
    }
    await getGPTReply(message);
  }

  async function startConversation() {
    await getGPTReply();
  }

  async function fetchConversation({ userId, npcId }) {
    const uid = userId || user?.id;
    const nid = npcId || npc?.id;
    if (uid && nid) {
      await fetch(`${SERVER_URL}conversations?user_id=${uid}&npc_id=${nid}`)
        .then((res) => res.json())
        .then((d) => {
          setConversations(d);
          if (d.length) {
            setConversation(d[0]);
          } else {
            setConversation(null);
          }
        });
    } else {
      setConversations([]);
      setConversation(null);
    }
  }

  const fetchInitialData = async () => {
    const response = await (await fetch(`${SERVER_URL}chat_data`)).json();
    setData(response);
    let u = null;
    let n = null;
    if (response.users.length) {
      u = response.users[0];
      setUser(u);
    }
    if (response.npcs.length) {
      n = response.npcs[0];
      setNPC(n);
    }

    if (u && n) {
      await fetchConversation({ userId: u.id, npcId: n.id });
    }
  };

  return {
    sendMessage,
    fetchConversation,
    fetchInitialData,
    startConversation,
  };
};

export default useChatActions;
