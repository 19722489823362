import React, { createContext, useContext, useState } from 'react';

const ChatContext = createContext();

export const useChat = () => useContext(ChatContext);

export function ChatProvider({ children }) {
  const [error, setError] = useState(null);
  const [data, setData] = useState([]);
  const [gpt, setGPT] = useState(null);
  const [user, setUser] = useState(null);
  const [npc, setNPC] = useState(null);
  const [conversation, setConversation] = useState(null);
  const [conversations, setConversations] = useState(null);

  const value = {
    data,
    setData,
    user,
    setUser,
    npc,
    setNPC,
    conversation,
    setConversation,
    conversations,
    setConversations,
    gpt,
    setGPT,
    error,
    setError,
  };

  return (
    <ChatContext.Provider value={value}>
      {children}
    </ChatContext.Provider>
  );
}
