import React, { useRef } from 'react';
import styled from 'styled-components';
import { IoIosSend } from 'react-icons/io';
import ButtonContainer from '../styled/Button';
import useChatActions from '../hooks/useChatActions';
import { useChat } from '../context/ChatProvider';

const MessageForm = styled.form`
    padding: 0.5vw 0;
    display: flex;
    align-items: center;
    margin-top: 1rem;
    margin-bottom: 1rem;

    border-top: 1px solid rgba(0, 0, 0, 0.08);

    & textarea {
        flex: 1;
        width: 100%;
        border: none;
        margin-right: 0.5rem;
    }
`;

function ChatForm() {
  const {
    conversation,
    setConversation,
    error,
  } = useChat();
  const inputRef = useRef(null);
  const { sendMessage, startConversation } = useChatActions();

  const handleNewMessage = async (e) => {
    e.preventDefault();
    const message = inputRef.current.value;
    try {
      inputRef.current.value = '';
      await sendMessage(message);
    } catch (err) {
      inputRef.current.value = message;
    }

    inputRef.current.focus();
  };

  const handleKeyDown = (evt) => {
    const keyCode = evt.keyCode;
    if (keyCode === 13) {
      handleNewMessage(evt);
    }
  };

  const onReset = () => setConversation(null);

  const onStartConversation = () => startConversation(null);

  const messageForm = (
    <>
      <MessageForm onSubmit={handleNewMessage}>
        <textarea
          type="text"
          rows="3"
          placeholder="Type a message here"
          ref={inputRef}
          onKeyDown={handleKeyDown}
        />

        <ButtonContainer flex="0" padding="0" active size="2.5em" borderRadius="50%">
          <button type="button" onClick={handleNewMessage}>
            <IoIosSend fill="#fff" />
          </button>
        </ButtonContainer>
      </MessageForm>
      {error && (
      <p className="text-danger">{error}</p>
      )}
    </>
  );

  let actionButtons = null;
  if (conversation) {
    actionButtons = (
      <button type="button" className="btn btn-primary" onClick={onReset}>
        Start new conversation
      </button>
    );
  } else {
    actionButtons = (
      <button type="button" className="btn btn-primary" onClick={onStartConversation}>
        NPC starts conversation
      </button>
    );
  }

  return (
    <>
      {messageForm}
      {actionButtons}
    </>
  );
}

export default ChatForm;
