import React from 'react';
import { createGlobalStyle } from 'styled-components';
import { ChatProvider } from './context/ChatProvider';
import Chat from './components/Chat';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';

const GlobalStyle = createGlobalStyle`
  :root {
    --main-color-dark-palette: #1a1a1a;
    --secondry-color-dark-palette: #373737;
    --blue-button-color: #3c95f4;
    --blue-active-color: #2070c6;
    --blue-gradient: linear-gradient(90deg, #3c95f4 65%, #3385dc 100%);
  }

  * {
    margin: 0;
    padding: 0;
    outline: transparent;
    text-decoration: none;
    box-sizing: border-box;
  }

  body {
    background: #ddd;
  }
`;

function App() {
  return (
    <>
      <h2 className="text-center my-4">EYWA TEST</h2>
      <hr />

      <GlobalStyle />

      <ChatProvider>
        <Chat />
      </ChatProvider>
    </>
  );
}

export default App;
