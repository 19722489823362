// import styled from 'styled-components'
import { Dropdown } from 'react-bootstrap';
import Container from 'react-bootstrap/Container';
import styled from 'styled-components';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import React, { useEffect } from 'react';
import ChatForm from './ChatForm';
import Conversation from './Conversation';
import { useChat } from '../context/ChatProvider';
import useChatActions from '../hooks/useChatActions';

const PromptParagarph = styled.div`
  white-space: pre-wrap;
  margin: 1rem;

  div.title {
    font-weight: bold;
    margin-bottom: 0.25rem;
  }
  div.description {
    border: 1px solid rgba(0, 0, 0, 0.08);
    padding: 1rem;
  }
`;

function Chat() {
  const {
    conversation,
    gpt,
    data,
    user,
    setUser,
    npc,
    setNPC,
    conversations,
    setConversation,
  } = useChat();
  const { fetchConversation, fetchInitialData } = useChatActions();

  const handleUserSelect = (userId) => {
    const userSelected = data.users.find((_user) => _user.id === userId);
    setUser(userSelected);
    fetchConversation({ userId });
  };

  const handleNPCSelect = (npcId) => {
    const npcSelected = data.npcs.find((_npc) => _npc.id === npcId);
    setNPC(npcSelected);
    fetchConversation({ npcId });
  };

  const handleConvoSelect = (convoId) => {
    const convoSelected = conversations.find((_convo) => _convo.id === convoId);
    setConversation(convoSelected);
  };

  useEffect(() => {
    fetchInitialData();
  }, []);

  const content = data && data.users && data.npcs ? (
    <>
      <Container fluid>
        <Row xs={1} md={3}>
          <Col>
            <Dropdown onSelect={handleNPCSelect}>
              <Dropdown.Toggle variant="success" id="dropdown-basic">
                NPCs
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {data?.npcs.map(
                  (_npc) => <Dropdown.Item eventKey={_npc.id}>{_npc.name}</Dropdown.Item>,
                )}
              </Dropdown.Menu>
            </Dropdown>
            {npc?.name}
          </Col>
          <Col>
            <Dropdown onSelect={handleUserSelect}>
              <Dropdown.Toggle variant="success" id="dropdown-basic">
                Users
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {data?.users?.map(
                  (_user) => <Dropdown.Item eventKey={_user.id}>{_user.name}</Dropdown.Item>,
                )}
              </Dropdown.Menu>
            </Dropdown>
            {user?.name}
          </Col>
          <Col>
            <Dropdown onSelect={handleConvoSelect}>
              <Dropdown.Toggle variant="primary" id="dropdown-basic">
                {conversations ? 'Conversations' : 'No previous conversations'}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {conversations?.map(
                  (convo) => (
                    <Dropdown.Item eventKey={convo.id}>
                      {`${convo.id}: ${convo.createdAt}`}
                    </Dropdown.Item>
                  ),
                )}
              </Dropdown.Menu>
            </Dropdown>
            {conversation?.id}
            {conversation?.createdAt && (
            <i>
              {' {'}
              {conversation?.createdAt}
              {'}'}
            </i>
            )}
          </Col>
        </Row>
      </Container>
      <hr />
      <Container fluid>
        <Row xs={1} lg={2}>
          <Col>
            <Conversation />

            <ChatForm />
          </Col>
          <Col>
            <h4 className="text-center">
              GPT Response
            </h4>
            {gpt && (
              <div>
                <PromptParagarph>
                  <div className="title">Prompt</div>
                  <div className="description">
                    {gpt.prompt}
                  </div>
                </PromptParagarph>
                <PromptParagarph>
                  <div className="title">Response</div>
                  <div className="description">
                    {gpt.response}
                  </div>
                </PromptParagarph>
                <PromptParagarph>
                  <div className="title">Latency</div>
                  <div className="description">
                    <p>
                      Prompt:
                      {' '}
                      {gpt.latency_prompt}
                      {' '}
                      seconds
                    </p>
                    <p>
                      LLM:
                      {' '}
                      {gpt.latency_llm}
                      {' '}
                      seconds
                    </p>
                    <p>
                      Total:
                      {' '}
                      {gpt.latency_prompt + gpt.latency_llm}
                      {' '}
                      seconds
                    </p>
                  </div>
                </PromptParagarph>
              </div>
            )}

          </Col>
        </Row>
      </Container>
    </>
  ) : (
    <div className="spinner-border" role="status">
      <span className="sr-only">Loading...</span>
    </div>
  );

  return (
    <div>
      {content}
    </div>
  );
}

export default Chat;
