import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import { useChat } from '../context/ChatProvider';

const ConversationContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1vh;
    flex: 1;
    padding: 20px 0;
    overflow: auto;
`;

const MessageContent = styled.div`
    display: flex;
    font-size: 1em;
    font-weight: 300;
    padding: 0.8em 1em;
    width: fit-content;
    height: fit-content;
`;

const MessageContainer = styled.div`
    display: flex;
    gap: 20px;
    color: #fff;
    font-size: 1rem;
    flex-direction: ${(props) => (props.bynpc ? 'row' : 'row-reverse')};
    margin-bottom: 0.5rem;

    ${MessageContent} {
        background: ${(props) => (props.bynpc ? '#333' : '#fff')};
        border: ${(props) => (props.bynpc ? 'none' : '1px solid rgba(0, 0, 0, 0.1)')};
        color: ${(props) => (props.bynpc ? '#fff' : '#000')};
        box-shadow:  ${(props) => (props.bynpc ? 'rgba(32, 112, 198, 0.4)' : 'rgba(0, 0, 0, 0.15)')} 2px 3px 15px;
        border-radius: ${(props) => (props.bynpc ? '0 8px 8px 8px' : '8px 0 8px 8px')};
    }
`;

const UserProfile = styled.div`
    color: black;

    &::before {
        content: '${(props) => props.content}';
        display: grid;
        place-content: center;
        padding: 0.5em;
        font-weight: bold;
    }
`;
function Conversation() {
  const { conversation, user, npc } = useChat();
  const chatConversation = useRef(null);

  // auto scroll to bottom on new message recieve / sent
  useEffect(() => {
    chatConversation.current.scrollTo(0, chatConversation.current.scrollHeight);
  }, [conversation]);

  return (
    <ConversationContainer ref={chatConversation}>
      {
        conversation?.items.map((m) => {
          const { message, byNpc } = m;

          return byNpc
            ? (
              <MessageContainer bynpc={byNpc}>
                <UserProfile content={npc.name} />
                <MessageContent>{message}</MessageContent>
              </MessageContainer>

            )
            : (
              <MessageContainer>
                <UserProfile content={user.name} />
                <MessageContent>{message}</MessageContent>
              </MessageContainer>
            );
        })
      }
    </ConversationContainer>
  );
}

export default Conversation;
